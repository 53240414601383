import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import TermsAndConditions1 from "../sections/terms-and-condition/terms-and-condition-1"

const TermsAndConditionPage = () => {
  return (
    <Layout>
      <Seo
        title="Terms and Conditions "
        description="The SuperBetter Terms and Conditions are a set of rules that hosts and players agree to follow. 
"
        keywords="SuperBetter Terms and Conditions"
      />
      <TermsAndConditions1 />
    </Layout>
  )
}

export default TermsAndConditionPage
